import logo from '../assets/images/logo.png';
import top_dish_1 from '../assets/images/top_dish_1.png';
import top_dish_2 from '../assets/images/top_dish_2.png';
import dishes_group from '../assets/images/dishes_group.jpg';
import tile_rist from '../assets/images/tile_rist.jpg';
import tile_past from '../assets/images/tile_past.jpg';
import tile_drinks from '../assets/images/tile_drinks.jpg';
import location_map from '../assets/images/location_map.jpg';
import placeholder from '../assets/images/placeholder.png';
import insta_1 from '../assets/images/instagram_post_1.jpg';
import insta_2 from '../assets/images/instagram_post_2.jpg';
import insta_3 from '../assets/images/instagram_post_3.jpg';
import insta_4 from '../assets/images/instagram_post_4.jpg';
import contacts_map from '../assets/images/contacts-map.jpg';

export default {
  logo: logo,
  top_dish_1: top_dish_1,
  top_dish_2: top_dish_2,
  dishes_group: dishes_group,
  location_map: location_map,
  placeholder: placeholder,
  contacts_map: contacts_map,
  landing_tiles: [tile_rist, tile_drinks, tile_past],
  insta_posts: [insta_1, insta_2, insta_3, insta_4],
};
