export default {
  SET_READY: 'SET_READY',
  SET_PROGRESS: 'SET_PROGRESS',
  BATCH_ACTIONS: 'BATCH_ACTIONS',
  SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_IS_MOBILE_MENU_OPEN: 'SET_IS_MOBILE_MENU_OPEN',
  SET_IS_ROUTED_FROM_MOBILE_MENU: 'SET_IS_ROUTED_FROM_MOBILE_MENU',
  SET_PREV_ROUTE: 'SET_PREV_ROUTE',
  LANDING_LOADED: 'LANDING_LOADED',
  SET_SITE_DATA: 'SET_SITE_DATA',
};
