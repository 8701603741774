import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'no-op';
import { BaseLink } from '@jam3/react-ui';
import { gsap } from 'gsap';
import checkProps from '@jam3/react-check-extra-props';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
import HamburgerButton, { STATES } from '../../components/HamburgerButton/HamburgerButton';
import Nav from '../../components/Nav/Nav';
import mediaContent from '../../data/media-content';
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook.svg';
import { ReactComponent as TripIcon } from '../../assets/svg/tripadvisor.svg';
import { ReactComponent as InstaIcon } from '../../assets/svg/instagram.svg';

import './Header.scss';

const getButtonState = (isMenuOpen) => (isMenuOpen ? STATES.close : STATES.idle);

class Header extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const nextButtonState = getButtonState(nextProps.isMobileMenuOpen);
    if (nextButtonState !== prevState.buttonState) {
      return {
        buttonState: nextButtonState,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      buttonState: getButtonState(props.isMobileMenuOpen),
      isAnimating: false,
    };
    this.tl = gsap.timeline();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isMobileMenuOpen && prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        isAnimating: true,
      });
      this.animateOut();
      this.props.setIsMobileMenuOpen(false);
    }
  }

  animateIn() {
    this.tl
      .fromTo(
        '.header__nav-content',
        0.5,
        { translateX: 0, autoAlpha: 1, height: '0', skewY: 10, bottom: '-20%' },
        {
          skewY: 0,
          height: '100vh',
          bottom: '0',
          ease: 'power1.out',
        }
      )
      .fromTo(
        '.svg-bg',
        1.3,
        { rotation: 55, autoAlpha: 0 },
        { rotation: 45, autoAlpha: 1, ease: 'power1.out', delay: '-0.1' }
      )
      .fromTo(
        '.main-nav__menu-li',
        0.6,
        { autoAlpha: 0, translateY: 30 },
        {
          autoAlpha: 1,
          translateY: 0,
          ease: 'expo.outin',
          delay: '-1.3',
          stagger: {
            each: 0.08,
          },
        }
      )
      .fromTo(
        '.info-nav__item',
        0.6,
        { autoAlpha: 0, translateY: 30 },
        {
          autoAlpha: 1,
          translateY: 0,
          ease: 'expo.outin',
          delay: '-1.2',
          stagger: {
            each: 0.08,
          },
          onComplete: () => {
            this.setState({
              isAnimating: false,
            });
          },
        }
      );
  }

  animateOut() {
    this.tl
      .to('.svg-bg', 0.6, { rotation: 55, autoAlpha: 0, ease: 'power1.in' })
      .to('.main-nav__menu-li', 0.2, {
        autoAlpha: 0,
        translateY: -10,
        ease: 'expo.inout',
        delay: '-0.4',
        stagger: {
          each: 0.08,
        },
      })
      .to('.info-nav__item', 0.2, {
        autoAlpha: 0,
        translateY: -10,
        ease: 'expo.inout',
        delay: '-0.1',
        stagger: {
          each: 0.08,
        },
      })
      .fromTo(
        '.header__nav-content',
        0.6,
        { skewY: 0, height: '100vh', ease: 'power1.in' },
        {
          skewY: -10,
          height: '0',
          bottom: '-50%',
          ease: 'power1.in',
          delay: '-0.3',
          onComplete: () => {
            gsap.to('.header__nav-content', 0, { clearProps: 'all' });
            this.setState({
              isAnimating: false,
            });
          },
        }
      );
  }

  handleHamburgerClick = () => {
    if (!this.state.isAnimating) {
      this.setState({
        isAnimating: true,
      });
      if (!this.props.isMobileMenuOpen) {
        this.animateIn();
      } else {
        this.animateOut();
      }
      this.props.setIsMobileMenuOpen(!this.props.isMobileMenuOpen);
    }
  };

  render() {
    const { logo } = mediaContent;
    return (
      <header
        className={classnames('Header', {
          'Header--active': this.props.isMobileMenuOpen,
        })}
      >
        <div className="Header__top-bar">
          <h1>
            <span>Centrale Montecatini</span>
            <PrefetchLink link="/" aria-label="Home" className="main-logo">
              <img src={logo} alt="logo" />
            </PrefetchLink>
          </h1>
          <div className="Header__top-bar__social">
            <BaseLink className="Header__top-bar__social__insta" link="https://www.instagram.com/centrale_montecatini/">
              <InstaIcon></InstaIcon>
            </BaseLink>
            <BaseLink className="Header__top-bar__social__fb" link="https://www.facebook.com/CentraleMontecatini">
              <FacebookIcon></FacebookIcon>
            </BaseLink>
            <BaseLink
              className="Header__top-bar__social__trip"
              link="https://www.tripadvisor.it/Restaurant_Review-g194830-d2294503-Reviews-Centrale_Il_Ristorante_La_Pizzeria-Montecatini_Terme_Province_of_Pistoia_Tuscany.html"
            >
              <TripIcon></TripIcon>
            </BaseLink>
          </div>
          <HamburgerButton onClick={this.handleHamburgerClick} currentState={this.state.buttonState} />
        </div>
        <Nav isMobileMenuOpen={this.props.isMobileMenuOpen} setIsMobileMenuOpen={this.props.setIsMobileMenuOpen} />
      </header>
    );
  }
}

Header.propTypes = checkProps({
  className: PropTypes.string,
  showHamburger: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  setIsMobileMenuOpen: PropTypes.func,
});

Header.defaultProps = {
  setIsMobileMenuOpen: noop,
};

export default withRouter(Header);
