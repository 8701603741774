import routeKeys from '../routes/keys';

// TransitionGroup v.2 requires the timeout to be passes down.
// Each page may have `in` and `exit` duration where `exit` duration controls when the page is removed from the DOM
// so it is required to have that specified here for each page

const transitionsData = {
  [`${routeKeys.Landing}`]: {
    exit: 600,
  },
  [`${routeKeys.Contatti}`]: {
    exit: 600,
  },
  [`${routeKeys.Ristorante}`]: {
    exit: 600,
  },
  [`${routeKeys.MenuBar}`]: {
    exit: 600,
  },
  [`${routeKeys.MenuRistorante}`]: {
    exit: 600,
  },
  [`${routeKeys.Drinks}`]: {
    exit: 600,
  },
  [`${routeKeys.Pasticceria}`]: {
    exit: 600,
  },
};

export function getEnterTransitionDuration(path) {
  path = '/' + path.split('/')[1];
  return transitionsData[path] ? transitionsData[path].enter : 600;
}

export function getExitTransitionDuration(path) {
  path = '/' + path.split('/')[1];
  return transitionsData[path] ? transitionsData[path].exit : 600;
}

export function getTransitionDuration(path) {
  path = '/' + path.split('/')[1];
  return transitionsData[path] || 600;
}
