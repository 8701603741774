import routeKeys from '../routes/keys';

export default {
  links: [
    {
      text: 'Home',
      path: routeKeys.Landing,
    },
    {
      text: 'Ristorante',
      path: routeKeys.Ristorante,
    },
    {
      text: 'Cocktails & Aperitivi',
      path: routeKeys.Drinks,
    },
    {
      text: 'Bar Pasticceria',
      path: routeKeys.Pasticceria,
    },
  ],
};
