import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BaseLink, Footer } from '@jam3/react-ui';
import checkProps from '@jam3/react-check-extra-props';
import 'default-passive-events';
import CookieConsent from 'react-cookie-consent';

import Pages from '../../components/Pages/Pages';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
import Header from '../../components/Header/Header';

import { setPreviousRoute } from '../../redux/modules/app';
import { setIsMobileMenuOpen } from '../../redux/modules/main-nav';

import settings from '../../data/settings';
import footerData from '../../data/footer';
import lockBodyScroll from '../../util/lock-body-scroll';
import sanitizer from '../../util/sanitizer';
import routeKeys from '../../routes/keys';
import mediaContent from '../../data/media-content';
import routePages from '../../routes/pages';

import backupData from '../../data/backup-data.json';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      siteData: null,
    };
  }

  async componentDidMount() {
    await this.setData();
    this.setState({
      fetched: true,
    });

    if (process.env.NODE_ENV !== 'production') {
      const { whyDidYouUpdate } = require('why-did-you-update');

      if (document.location.search.indexOf('performance') >= 0) {
        whyDidYouUpdate(React);
      }
    }

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobileMenuOpen !== this.props.isMobileMenuOpen) {
      this.props.isMobileMenuOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setPreviousRoute(prevProps.location.pathname);
    }
  }

  // componentWillUnmount() {
  // }

  async setData() {
    const fetchedData = {};
    const pages = `${settings.cms}pages?per_page=16`;
    await fetch(pages)
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          routePages.forEach((pageName) => {
            const pageData = data.filter((page) => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData,
          });
        } else {
          routePages.forEach((pageName) => {
            const pageData = backupData.filter((page) => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData,
          });
        }
      })
      .catch((error) => {
        routePages.forEach((pageName) => {
          const pageData = backupData.filter((page) => page.slug === pageName);
          if (pageData[0]) {
            fetchedData[pageData[0].slug] = pageData[0];
          }
        });
        this.setState({
          siteData: fetchedData,
        });
      });
  }

  render() {
    return (
      <Fragment>
        {this.state.siteData && (
          <Fragment>
            <Header
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              setIsMobileMenuOpen={this.props.setIsMobileMenuOpen}
            />
            <Pages siteData={this.state.siteData} />
            <Footer {...footerData} linkComponent={PrefetchLink}>
              <div className="footer-brand">
                <BaseLink link={routeKeys.Landing}>
                  <img src={mediaContent.logo} alt="centrale montecatini logo" />
                </BaseLink>
                <span>{sanitizer('Piazza del Popolo, 19 - Montecatini Terme')}</span>
              </div>
            </Footer>
          </Fragment>
        )}
        <CookieConsent disableStyles={true} buttonText={'Acconsento'}>
          {sanitizer("Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.")}
        </CookieConsent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileMenuOpen: state.isMobileMenuOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPreviousRoute: (val) => dispatch(setPreviousRoute(val)),
    setIsMobileMenuOpen: (val) => dispatch(setIsMobileMenuOpen(val)),
  };
};

App.propTypes = checkProps({
  setPreviousRoute: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
});

App.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
