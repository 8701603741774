export default {
  Landing: '/',
  MenuBar: '/menu-bar',
  MenuRistorante: '/menu-ristorante',
  Ristorante: '/ristorante',
  Drinks: '/drinks',
  Pasticceria: '/pasticceria',
  Eventi: '/eventi',
  Contatti: '/contatti',
};
